

.dicomUploadContainer{
    position: absolute;
    bottom: 25px;
    left: 25px;
}


.dicomUploadThumbnail{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 140px;
    height: 45px;
    border-radius: 10px;
    overflow: hidden; 
    background-color: rgb(180,180,180);
    opacity: 0.7;
    text-align: center;
    cursor: pointer;
}

.dicomUploadThumbnail:hover{
    opacity: 0.9;
}

.dicomUploadThumbnail > img {
    width: 25px;
    display: block;
    position: absolute;
    right: 5px;
    top: 10px;
    opacity: 1;
    
}

.dicomUploadThumbnail > p {
    position: absolute;
    bottom: 45%;
    left: 5px;
    transform: translateY(50%);
    font-style: 10px;
    opacity: 0.8;
}



.dicomUploadConsole {
    background-color: rgba(200,200,200,0.6);
    border-radius: 15px;
    width: 255px;
    height: 330px;

    position: absolute;
    bottom: 45px;
    left: 0px;
}

.dicomUploadTracker {
    position: absolute;
    top: 5px;
    margin-left: 10px;
    width: 90%;
    height: 150px;
    padding-top: 5px;

    border-radius: 15px;
    overflow-y: scroll;
}

.dicomUploadNotice {
    position: absolute;
    bottom: 12px;
    margin-left: 11px;
    font-size: 14px;
}

.dicomUploadTracker::-webkit-scrollbar{
    display: none;
}

.dicomUploadTracker > p{
    display: inline-block;
    margin-left: 10px;
}


.dicomUploadDrop {
    position: absolute;
    height: 130px;
    width: 90%;
    bottom: 38px;
    margin-left: 10px;

    border-radius: 15px;
}



.dicomUploadBox {

    position: absolute;
    height: 95%;
    width: 95%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    border-radius: 6px;
    border: 2px dashed rgba(160,160,160,1);
    background-color: rgba(200,200,200,0.3);
    cursor: pointer;
    opacity: 0.5;
    transition: all 0.1s ease-in-out;
}

.dicomUploadBox:hover {
    opacity: 1;
}

.dicomUploadBox > img {
    position: absolute;
    height: 50%;
    width: 50%;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.dicomUploadBox > p {
    position: relative;
    top: 47%;
    font-size: 16px;
}


.loadingIcon {
    position: absolute;
    height: 84px;
    width: 154px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}