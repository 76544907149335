/*
Developed by Aptus Engineering, Inc. <https://aptus.aero>
See LICENSE.md file in project root directory
*/

.consoleContainer {
    position: absolute;
    top: 72px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    overflow: hidden;
    background-image: url('/resources/cover.jpg');
    background-size: cover;
    min-width: 1300px;
    overflow-y: scroll;
    padding: 10px;
}

.consoleHeader {
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    height: 32px;
    background-color: rgba(120,120,120);
    border-bottom: 2px solid rgb(160,160,160);
    z-index: 2;
    padding-left: 20px;
    padding-right: 40px;
    line-height: 32px;
    font-size: 14px;
    color: rgb(240,240,240);
    font-weight: 400;
    min-width: 1100px;
    overflow: hidden;
}

.consoleHeaderButton {
    float: right;
    font-size: 15px;
    transition: all 0.1s ease-in-out;
    height: 32px;
    line-height: 32px;
    padding: 0 10px;
    cursor: pointer;
}

.consoleHeaderButton:hover {
    color: rgb(230,155,30);
}