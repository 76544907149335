/*
Developed by Aptus Engineering, Inc. <https://aptus.aero>
See LICENSE.md file in reviewProject root directory
*/

.reviewProjectRow {
    position: relative;

    height: 32px;
    
    margin-top: 6px;
    margin-left: 40px;
    margin-right: 40px;

    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 20px;
    padding-right: 20px;

    line-height: 20px;

    color: rgb(40,40,40);
    background-color: rgba(160,160,160,0.3);
    transition: all 0.1s ease-in-out;

    overflow: hidden;
}

.reviewProjectRow:hover {
    margin-left: 10px;
    margin-right: 10px;

    padding-left: 50px;
    padding-right: 50px;

    background-color: rgba(160,160,160,0.4);
}

.reviewProjectRowUpload {
    height: 80px;
    margin-top: 40px;
    transition: all 0.1s ease-in-out;
}

.reviewProjectRowItem {
    float: left;
    padding: 0 5px;
    height: 32px;
    line-height: 32px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.reviewProjectRow > img {
    width: 30px;
    height: 30px;
    float: right;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    margin-left: 10px;
}

.reviewProjectRow > img:hover {
    transform: scale(1.15);
}

.reviewRadButton {
    background-color: rgb(80,80,80);
    color: rgb(220,220,220);
    padding: 2px 10px;
    border-radius: 12px;
    width: 62px;
    transition: all 0.1s ease-in-out;
    cursor: pointer;
    background-image: url('/resources/icons/next.png');
    background-repeat: no-repeat;
    background-size: 24px;
    background-position: 58px 0;
    height: 20px;
    line-height: 20px;
    margin-top: 4px;
    float: left;
    margin-right: 6px;
}

.reviewRadButton:hover {
    background-color: rgb(60,60,60);
    color: rgb(230,155,30);
    width: 70px;
    background-position: 66px 0;
}


.switchContainer {
    border: none;
    border-radius: 5px;
    width: 175px;
    margin: 0;
    height: 22px;
    line-height: 22px;
    font-size: 13px;
    color: rgb(80,80,80);
    display: inline-block;
}

.switchLabel {
    display: inline-block;
    margin-left: 10px;
}

.switchCheckbox {
    vertical-align: middle;
}