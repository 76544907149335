/*
Developed by Aptus Engineering, Inc. <https://aptus.aero>
See LICENSE.md file in project root directory
*/

.header {
    height: 40px;
    line-height: 40px;
    padding: 0 40px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: rgb(60,60,60);
    color: rgb(220,220,220);
    border-bottom: 2px solid rgb(120,120,120);
    min-width: 1100px;
}

.header > img {
    height: 24px;
    margin-top: 8px;
    margin-left: -32px;
    position: fixed;
    z-index: 100;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
}

.header > img:hover {
    transform: scale(1.2);
}

.header > h1 {
    height: 40px;
    font-size: 18px;
    font-weight: 200;
    text-transform: uppercase;
    letter-spacing: 2px;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
}

.header > h1:hover {
    color: white;
}

.header > ul {
    list-style: none;
    display: flex;
    position: fixed;
    top: 0;
    right: 40px;
    height: 40px;
}

.header > ul > li {
    font-size: 16px;
    margin-left: 24px;
    height: 40px;
}

.navLink, .header > ul > li > a {
    color: rgb(220, 220, 220);
    font-weight: 200;
    text-decoration: none;
    transition: all 0.1s ease-in-out;
    display: block;
    height: 40px;
    cursor: pointer;
}

.navLink:hover, .header > ul > li > a:hover {
    color: rgb(230,155,30);
}

.activeRoute {
    border-top: solid 4px rgb(230,155,30);
    line-height: 32px;
}

.profileContainer {
    right: 12px;
    position: fixed;
    background-color: white;
    border: 1px solid rgb(180,180,180);
    border-radius: 4px;
    width: 200px;
    height: 142px;
    top: 40px;
    z-index: 100;
}

.profileUserName {
    top: 16px;
    left: 10px;
    position: absolute;
    color: rgb(80,80,80);
    font-size: 16px;
    line-height: 20px;
    height: 20px;
}

.profileButton {
    position: absolute;
    left: 4px;
    right: 4px;
    height: 30px;
    border-radius: 4px;
    line-height: 30px;
    text-align: center;
    color: white;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
}

.changePasswordButton {
    top: 74px;
    background-color: rgb(0,80,120);
}

.changePasswordButton:hover {
    background-color: rgb(0,110,150);
}

.confirmButton {
    top: 74px;
    left: 120px;
    background-color: rgb(0,80,120);
}

.confirmButton:hover {
    background-color: rgb(0,110,150);
}

.logoutButton {
    top: 108px;
    background-color: rgb(230,155,30);
}

.logoutButton:hover {
    background-color: rgb(250,175,60);
}

.profileContainer > input[type=password] {
    position: absolute;
    left: 4px;
    top: 74px;
    width: 90px;
    padding: 4px 10px;
    height: 20px;
    line-height: 20px;
    border: 1px solid rgb(220,220,220);
    border-radius: 6px;
    background-color: rgba(240,240,240,0.6);
    color: black;
    float: right;
    font-size: 14px;
}


.toggleContainer {
    position: absolute;
    top: 42px;
    left: 4px;
    border: none;
    border-radius: 5px;
    width: 175px;
    margin: 0;
    height: 22px;
    line-height: 22px;
    font-size: 13px;
    color: rgb(80,80,80);
    display: inline-block;
}

.toggleLabel {
    display: inline-block;
    margin-left: 10px;
}

.toggleCheckbox {
    vertical-align: middle;
}