/*
Developed by Aptus Engineering, Inc. <https://aptus.aero>
See LICENSE.md file in project root directory
*/

.accessHeader {
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    height: 32px;
    background-color: rgba(120,120,120);
    border-bottom: 2px solid rgb(160,160,160);
    z-index: 2;
    padding-left: 60px;
    padding-right: 60px;
    line-height: 32px;
    font-size: 14px;
    color: rgb(240,240,240);
    font-weight: 400;
    min-width: 1100px;
    overflow: hidden;
}

.accessHeader > a, .accessControlItem {
    color: rgb(240, 240, 240);
    font-weight: 200;
    text-decoration: none;
    transition: all 0.1s ease-in-out;
    font-size: 16px;
    cursor: pointer;
}

.accessControls {
    float: right;
}

.accessControlItem {
    float: right;
    margin-left: 24px;
    font-size: 14px;
}

.accessControls > input[type=text] {
    height: 16px;
    line-height: 16px;
    padding: 4px 10px;
    width: 120px;
    border: none;
    margin-top: 4px;
    border-radius: 6px;
    background-color: rgba(240,240,240,0.6);
    color: black;
    float: right;
    margin-left: 24px;
    font-size: 13px;
}

.accessHeader > a:hover, .accessControlItem:hover {
    color: rgb(230,155,30);
}

.accessDirectory {
    position: absolute;
    top: 72px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-image: url('/resources/cover.jpg');
    background-size: cover;
    overflow: hidden;
    display: flex;
}

.accessContentView {
    flex-grow: 1;
    z-index: 1;
    padding: 30px;
    overflow: hidden;
}

.accessSideView {
    flex-shrink: 0;
    height: 100%;
    z-index: 1;
    /* border-left: 2px solid #a0a0a0; */
    background-color: rgb(254,254,254);
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
    width: 340px;
    overflow: hidden;
}

.accessSideView .listGroup {
    display: block;
    margin: 20px;
    padding: 15px 0;
    border-bottom: 1px solid rgb(220,220,220);
}

.accessSideView .listGroup:last-of-type {
    border-bottom: none;
}

.accessSideView .listGroup h3 {
    font-weight: normal;
    font-size: 16px;
    color: rgb(120,120,120);
    padding: 2px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.accessSideView .listGroup h2 {
    font-weight: normal;
    color: rgb(40,40,40);
    padding: 6px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.accessSideView .listGroup label {
    display: inline-block;
    color: #a0a0a0;
    padding-bottom: 0.2rem;
    font-size: 15px;
    border-bottom: 2px solid #ff9b1e;
    margin-bottom: 6px;
}

.accessSideView .listGroup .badge {
    background-color: #ff9b1e;
    margin: 0 10px;
    width: 25px;
    border-radius: 17px;
    display: inline-block;
    text-align: center;
}

.accessSideView .downloadAll {
    /* display: inline-block;
    margin: 0 10px;
    width: calc(100% - 20px); */
    background-color: #787878;
    border: none;
    padding: 10px;
    position: fixed;
    right: 10px;
    bottom: 10px;
    width: 320px;
}

.accessSideView .downloadAll:hover {
    background-color: #5f5f5f;
}

.accessSideView .imageContainer {
    position: relative;
}

.accessSideView .imageContainer h1 {
    position: absolute;
    top: 10px;
    left: 10px;
    color: white;
    font-size: 24px;
    border: none;
    font-weight: normal;
}

.accessSideView .imageContainer .placeHolder {
    width: 100%;
    height: 200px;
    background-color: #383838;
    display: flex;
    align-items: center;
    justify-content: center;
}

.accessSideView .imageContainer .placeHolder h2 {
    color: rgb(34, 34, 34);
}

.accessSideView .imageContainer .btn {
    position: absolute;
    bottom: 10px;
    left: 10px;
    background-color: rgba(40,40,40,0.7);
    color: white;
    font-size: 16px;
    padding: 8px 16px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.1s ease-in-out;
}
  
.accessSideView .imageContainer .btn:hover {
    background-color: rgba(20,20,20,0.9);
    color: #ff9b1e;
}

.accessSideView .imageContainer .btn * {
    display: inline-block;
    vertical-align: middle;
}

.accessContainer {
    height: 100%;
    flex-grow: 1;
    z-index: 1;
    overflow: hidden;
    background-color: rgb(84,86,89);
    transition: ease-in-out 0.15s all;
}

.accessContainerInEdit {
    left: 320px;
}

.fileViewerRoot {

    position: absolute;
    top: 74px;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: stretch;

}

.accessFile {
    height: 160px;
    width: 140px;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    margin: 10px;
    cursor: pointer;
    text-align: center;
    color: rgb(80,80,80);
    font-size: 16px;
    line-height: 24px;
    float: left;
}

.accessFile:hover {
    transform: scale(1.1);
    transition: all 0.1s ease-in-out;
}

.accessFile > img {
    width: 100px;
    height: auto;
}

.imageViewer {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: rgb(84,86,89);
    text-align: center;
}

.imageViewer > img {
    max-height: 100%;
    max-width: 100%;
    height: 100%;
    object-fit: contain;
    margin: 0 auto;
}

.loadingIconContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -60px;
    margin-top: -60px;
}

.noteMemoContainer {
    position: absolute;
    width: 300px;
    background-color: rgba(250,230,0,0.7);
    right: 20px;
    top: 60px;
    color: black;
    padding-bottom: 30px;
}

.noteMemoSaveButton {
    position: absolute;
    width: 60px;
    height: 24px;
    line-height: 24px;
    color: white;
    background-color: rgb(80,80,80);
    border-radius: 5px;
    text-align: center;
    right: 5px;
    bottom: 5px;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
}

.noteMemoSaveButton:hover {
    background-color: rgb(60,60,60);
    color: rgb(230,155,30);
}

.noteMemoMic {
    position: absolute;
    width: 16px;
    height: 16px;
    left: 8px;
    bottom: 8px;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
}

.noteMemoMic:hover {
    transform: scale(1.3);
}

.noteMemoRecording {
    position: absolute;
    color: rgb(100,100,100);
    bottom: 8px;
    line-height: 16px;
    font-style: italic;
    left: 30px;
    font-size: 13px;
}

textarea {
    width: calc(100% - 20px);
    max-width: calc(100% - 20px);
    height: 150px;
    padding: 10px;
    border: none;
    margin: 0;
    background-color: rgba(0,0,0,0);
}

.coverOverlay {
    position: absolute;
    top: 42px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(30,30,30,0.85);
    z-index: 200;
    display: none;
    transition: all 0.3s ease-in-out;
}

.accessKeyForm {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -160px;
    margin-top: -20px;
    width: 300px;
    height: 30px;
    padding: 5px;
    border-radius: 10px;
    background-color: rgb(200,200,200);
    border: 1px solid rgb(160,160,160);
}

.accessKeyForm > input[type=text] {
    height: 22px;
    line-height: 22px;
    padding: 4px 10px;
    border: none;
    margin: 0;
    border-radius: 6px;
    background-color: rgba(240,240,240,0.6);
    color: rgb(60,60,60);
    float: left;
    font-size: 13px;
    width: 200px;
}

.accessKeySubmitButton {
    height: 22px;
    line-height: 22px;
    padding: 4px 10px;
    border: none;
    margin: 0;
    border-radius: 6px;
    float: right;
    width: 52px;
    /* background-color: rgb(11, 90, 121); */
    background-color: rgb(80,80,80);
    color: white;
    text-align: center;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
}

.accessKeySubmitButton:hover {
    background-color: rgb(60,60,60);
    color: #ff9b1e;
}

.dropzone {
    height: 140px;
    width: 120px;
    border-radius: 6px;
    float: right;
    margin-left: 20px;
    border: 2px dashed rgba(160,160,160,1);
    background-color: rgba(200,200,200,0.3);
    cursor: pointer;
    opacity: 0.5;
    transition: all 0.1s ease-in-out;
    position: relative;
}

.dropzone:hover {
    opacity: 1;
}

.dropzone > img {
    height: 35px;
    width: 35px;
    margin-top: 40px;
}

.endUserBackButton {
    width: 120px;
    height: 40px;
    background-color: rgba(0, 0, 0, .6);
    color: #fff;
    text-align: center;
    position: fixed;
    bottom: 20px;
    left: calc(50% - 60px);
    margin: 0px;
    padding: 0px;
    border: none !important;
    outline: none !important;
    border-radius: 4px;
    z-index: 9999;
    line-height: 40px;
    transition: all 0.15s ease-in-out;
    cursor: pointer;
}

.endUserBackButton:hover {
    background-color: rgba(0,0,0,0.8);
    color: #ff9b1e;
}