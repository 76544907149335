/*
Developed by Aptus Engineering, Inc. <https://aptus.aero>
See LICENSE.md file in project root directory
*/

.projectsRootContainer {
    position: fixed;
    z-index: 0;
    width: 100%;
    height: 100%;
}

.projectsHeader {
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    height: 32px;
    background-color: rgb(120,120,120);
    border-bottom: 2px solid rgb(160,160,160);
    z-index: 100;
    padding-left: 60px;
    padding-right: 75px;
    line-height: 32px;
    font-size: 14px;
    color: rgb(240,240,240);
    font-weight: 400;
    min-width: 1100px;
    overflow: hidden;
}

.projectsHeaderItem {
    float: left;
    padding-left: 5px;
    margin-right: -5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    user-select: none;
    cursor: pointer;
}

.projectsHeaderItemOther {
    display: inline-block;
    padding-left: 4px;
    font-size: 14px;
    cursor: pointer;
}

.projectsHeaderItemOther > img {
    margin-top: 4px;
    height: 24px;
    width: 24px;
    float: right;
    transition: all 0.05s ease-in-out;
    cursor: pointer;
    opacity: 0.5;
    margin-right: 5px;
}

.projectsHeaderItemOther > img:hover {
    opacity: 1;
}

.projectsContainer {
    position: absolute;
    top: 72px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background-image: url('/resources/cover.jpg');
    background-size: cover;
    min-width: 1300px;
    overflow-y: scroll;
}

.grayedOut {
    opacity: 0.3;
}

.notesMemosContainer {
    float: right;
    width: 100%;
    margin-top: 10px;
}

.projectNote {
    height: 32px;
    line-height: 32px;
    text-align: right;
    color: rgb(80,80,80);
    font-style: italic;
    width: 100%;
}

.projectNote > img {
    height: 20px;
    width: 20px;
    margin-top: 2px;
    margin-left: 20px;
    margin-right: 8px;
}

.targetFile {
    height: 24px;
    border-radius: 5px;
    background-color: rgb(100,100,100);
    color: white;
    line-height: 24px;
    margin-left: 12px;
    padding-left: 5px;
    padding-right: 5px;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    display: inline-block;
    font-style: normal;
}

.targetFile:hover {
    background-color: rgb(60,60,60);
    color: rgb(230,155,30);
}

.lastAccessFilter {
    width: 100%;
    height: 24px;
    line-height: 24px;
    color: rgb(120,120,120);
    font-size: 14px;
    text-align:center;
    margin-top: 16px;
    margin-bottom: 20px;
}

.switchFetchLink {
    display: inline-block;
    text-align:center;
    cursor: pointer;
}

.lastAccessFilterSelect {
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 5px;
    padding-left: 2px;
    padding-right: 2px;
    height: 22px;
    line-height: 22px;
    width: 40px;
    overflow: hidden;
    background-color: rgba(225,225,225,0.8);
    color: rgb(80,80,80);
    border: none;
}

.paging {
    position: absolute;
    right: 116px;
}

.pagingArrow {
    display: inline;
    transition: all 0.1s ease-in-out;
}

.pagingArrow:hover {
    color: rgb(230,155,30);
}