/*
Developed by Aptus Engineering, Inc. <https://aptus.aero>
See LICENSE.md file in reviewProject root directory
*/

.docsReviewHeader {
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    height: 32px;
    background-color: rgba(120,120,120);
    border-bottom: 2px solid rgb(160,160,160);
    z-index: 2;
    padding-left: 60px;
    padding-right: 60px;
    line-height: 32px;
    font-size: 14px;
    color: rgb(240,240,240);
    font-weight: 400;
    min-width: 1100px;
    overflow: hidden;
}

.docsReviewHeader > a {
    color: rgb(240, 240, 240);
    font-weight: 200;
    text-decoration: none;
    transition: all 0.1s ease-in-out;
    font-size: 16px;
    cursor: pointer;
}

.docsReviewHeader > a:hover {
    color: rgb(230,155,30);
}

.docsReviewContainer {
    position: absolute;
    top: 72px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    overflow: hidden;
    background-color: rgb(84,86,89);
    text-align: center;
}

.docsReviewContainer > img {
    max-height: 100%;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    margin: 0 auto;
}

.docsReviewButton {
    float: right;
    height: 14px;
    line-height: 14px;
    color: white;
    padding: 5px 10px;
    transition: all 0.1s ease-in-out;
    margin-left: 10px;
    margin-top: 4px;
    border-radius: 5px;
    cursor: pointer;
}

.docsReviewButton:hover {
    transform: scale(1.1);
}

.docsReviewNextButton {
    background-color: rgb(0,120,0);
}

.docsReviewNextButton:hover {
    background-color: rgb(0,160,0);
}

.docsReviewDisabledButton {
    background-color: rgb(120,120,120);
}

.docsReviewDisabledButton:hover {
    background-color: rgb(120,120,120);
}

.docsReviewBackButton {
    background-color: rgb(120,0,0);
}

.docsReviewBackButton:hover {
    background-color: rgb(160,0,0);
}

.docsReviewSelect {
    float :right;
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
    height: 24px;
    margin-top: 4px;
    line-height: 24px;
    width: 240px;
    background-color: rgb(100,100,100);
    color: rgb(225,225,225);
    border: none;
}