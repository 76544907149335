/*
Developed by Aptus Engineering, Inc. <https://aptus.aero>
See LICENSE.md file in project root directory
*/

.leadProjectRow {
    position: relative;

    height: 32px;
    
    margin-top: 6px;
    margin-left: 40px;
    margin-right: 40px;

    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 20px;
    padding-right: 20px;

    line-height: 20px;

    color: rgb(40,40,40);
    background-color: rgba(160,160,160,0.3);
    transition: all 0.1s ease-in-out;

    overflow: hidden;

    cursor: pointer;
}

.leadProjectRow:hover {
    margin-left: 10px;
    margin-right: 10px;

    padding-left: 50px;
    padding-right: 50px;

    background-color: rgba(160,160,160,0.4);
}

.leadProjectRowItemContainer {
    width: calc(100% - 80px);
}

.leadProjectRowItem {
    float: left;
    padding: 0 5px;
    height: 32px;
    line-height: 32px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.leadProjectRow > img {
    width: 30px;
    height: 30px;
    float: right;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    margin-left: 10px;
}

.leadProjectRow > img:hover {
    transform: scale(1.15);
}