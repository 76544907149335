/*
Developed by Aptus Engineering, Inc. <https://aptus.aero>
See LICENSE.md file in project root directory
*/

.jobManagerContainer {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
}

.jobManager {
    position: absolute;
    width: 300px;
    top: 0px;
    right: 0;
    bottom: 0;
    background-color: rgb(250,250,250);
    border-left: 1px solid rgb(160,160,160);
    box-shadow: -5px 0 10px -2px rgba(0,0,0,0.3);
    transition: 0.1s all ease-in-out;
    padding-left: 10px;
    padding-right: 22px;
}

.jobManagerTitle {
    margin-top: 80px;
    font-size: 24px;
    line-height: 48px;
    color: rgb(100,100,100);
    border-bottom: 1px solid rgb(220,220,220);
}

.jobManagerTask {
    width: 100%;
    background-color: rgb(235,235,235);
    margin-top: 10px;
    transition: 0.1s all ease-in-out;
    overflow: hidden;
    padding-bottom: 10px;
    position: relative;
}

.jobType {
    width: calc(100% - 20px);
    padding: 0 10px;
    height: 30px;
    line-height: 30px;
    font-size: 15px;
    color: white;
    background-color: rgb(120,120,120);
    cursor: pointer;
    margin-bottom: 10px;
}

.jobStatus {
    float: right;
    line-height: 16px;
    height: 16px;
    padding: 3px 6px;
    margin: 4px 0;
    border-radius: 5px;
    font-weight: 500;
}

.killJob {
    height: 14px;
    line-height: 14px;
    background-color: rgb(160,0,0);
    border: 1px solid rgb(220,0,0);
    margin-right: 5px;
    cursor: pointer;
    transition: 0.1s all ease-in-out;
}

.killJob:hover {
    background-color: rgb(220,0,0);
}

.jobField {
    height: 25px;
    line-height: 25px;
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
    vertical-align: top;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.jobLabel {
    display: block;
    float: left;
    width: 80px;
    font-weight: 500;
}

.jobAction {
    float: left;
    display: block;
    padding: 0 6px;
    height: 20px;
    line-height: 20px;
    background-color: rgb(200,120,0);
    color: white;
    margin-top: 4px;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.1s all ease-in-out;
}

.jobReview {
    background-color: rgb(100,140,200);
}

.jobActionLoading {
    float: left;
    display: block;
    padding: 0 6px;
    height: 20px;
    line-height: 20px;
    color: white;
    margin-top: 4px;
}

.jobAction:hover {
    background-color: rgb(220,160,0);
}

.jobReview:hover {
    background-color: rgb(100,180,240);
}

.jobValueBlock {
    display: inline-block;
}

.injuryStatusIcon {
    width: 14px;
    height: 14px;
    margin-bottom: -2px;
    margin-right: 4px;
    transition: 0.1s all ease-in-out;
}

.injuryStatusIcon:hover {
    transform: scale(1.4);
}

.progressBarContainer {
    height: 4px;
    width: 100%;
    float: left;
    position: absolute;
    left: 0;
    top: 30px;
    background-color: rgb(0,0,0,0.15);
}

.injurySummaryExistsIcon {
    margin-left: 6px;
}

.progressBar {
    height: 4px;
    background-color: rgb(0,180,100);
    animation-name: pulseColor;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    float: left;
    position: absolute;
    left: 0;
    top: 0;
}

@keyframes pulseColor {
    0% {
        background-color: rgb(0,180,100);
    }
    50% {
        background-color: rgb(0,240,140);
    }
    100% {
        background-color: rgb(0,180,100);
    }
}