/*
Developed by Aptus Engineering, Inc. <https://aptus.aero>
See LICENSE.md file in project root directory
*/

.clientCard {
    display: inline-block;
    width: 345px;
    height: 270px;
    margin: 10px;
    padding: 6px;
    border-radius: 10px;
    background-color: rgba(200,200,200,0.5);
    transition: all 0.1s ease-in-out;
    position: relative;
    overflow: hidden;
}

.clientInfoChanged {
    background-color: rgba(220,180,180,0.5);
}


.clientCard:hover {
    background-color: rgba(200,200,200,0.6);
    /* transform: scale(1.05); */
}

.clientInfoChanged:hover {
    background-color: rgba(220,180,180,0.6);
}

.clientButton {
    position: absolute;
    right: 5px;
    width: 72px;
    color: white;
    text-align: center;
    line-height: 24px;
    height: 24px;
    border-radius: 7px;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
}

.clientSaveButton {
    bottom: 34px;
    background-color: rgba(0,100,40,0.7);
}

.clientSaveButton:hover {
    background-color: rgba(0,140,80,0.7);
}

.clientDeleteButton {
    bottom: 5px;
    background-color: rgba(100,0,0,0.7);
}

.clientDeleteButton:hover {
    background-color: rgba(140,0,0,0.7);
}

.clientExpandButton {
    background-color: rgba(180,180,180,0.5);
    top: 5px;
    bottom: 66px;
    color: rgb(80,80,80);
}

.clientExpandButton:hover {
    background-color: rgba(180,180,180,1);
}

.clientFilterType {
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    height: 22px;
    line-height: 22px;
    font-size: 14px;
    width: 110px;
    margin-right: 6px;
    overflow: hidden;
    background-color: rgba(245,245,245,0.3);
    color: rgb(40,40,40);
    border: none;
}

.clientFilterSearch {
    border: none;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    width: 220px;
    height: 22px;
    line-height: 22px;
    font-size: 15px;
    background-color: rgba(245,245,245,0.3);
    color: rgb(40,40,40);
}

.clientFilterSearch::placeholder {
    color: rgb(80,80,80);
}

.clientCardLabel {
    color: rgb(120,120,120);
    height: 22px;
    width: 72px;
    line-height: 22px;
    padding-left: 10px;
    font-size: 13px;
    display: inline-block;
    margin-bottom: 6px;
}

.clientDataLabel {
    color: rgb(120,120,120);
    height: 22px;
    width: 80px;
    line-height: 22px;
    font-size: 13px;
    display: inline-block;
    margin-bottom: 6px;
}

.resetPasswordButton {
    background-color: rgba(0,80,120,0.8);
    color: white;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    position: absolute;
    left: 88px;
    top: 62px;
    width: 175px;
    height: 24px;
    line-height: 24px;
    cursor: pointer;
    opacity: 0;
    z-index: 10;
}

.resetPasswordButton:hover {
    opacity: 1;
}

.clientCard > input[type=text], .clientCard > input[type=password], .clientDataContent > input[type=text] {
    border: none;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    width: 155px;
    margin: 0;
    height: 22px;
    line-height: 22px;
    font-size: 13px;
    background-color: rgba(250,250,250,0.8);
    color: rgb(80,80,80);
    display: inline-block;
}

.clientCard > input[type=password] {
    width: 175px;
}

.clientDataContainer {
    position: absolute;
    left: 279px;
    top: 6px;
    bottom: 6px;
    right: 94px;
}

.clientDataContent {
    position: relative;
}

.clientLogo {
    position: absolute;
    bottom: 6px;
    left: 88px;
    height: 40px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border-radius: 5px;
    background-color: white;
}

.clientLogoOverlay {
    width: 100%;
    height: 100%;
    background-color: rgba(80,80,80,0.8);
    border-radius: 5px;
    text-align: center;
    line-height: 40px;
    color: white;
    opacity: 0.1;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.clientLogoOverlay:hover {
    opacity: 1;
}

.clientLogoClear {
    position: absolute;
    left: 218px;
    bottom: 6px;
    width: 45px;
    height: 40px;
    background-color: rgb(100,100,100);
    border-radius: 5px;
    line-height: 40px;
    text-align: center;
    color: white;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.clientLogoClear:hover {
    background-color: rgb(160,160,160);
}

.clientDataSelect {
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 1px;
    height: 22px;
    line-height: 22px;
    width: 100%;
    overflow: hidden;
    background-color: rgba(250,250,250,0.8);
    color: rgb(80,80,80);
    border: none;
}

.clientTypeSelect {
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 1px;
    height: 22px;
    line-height: 22px;
    width: 175px;
    overflow: hidden;
    background-color: rgba(250,250,250,0.8);
    color: rgb(80,80,80);
    border: none;
}

.switchContainer {
    border: none;
    border-radius: 5px;
    width: 175px;
    margin: 0;
    height: 22px;
    line-height: 22px;
    font-size: 13px;
    color: rgb(80,80,80);
    display: inline-block;
}

.switchLabel {
    display: inline-block;
    margin-left: 10px;
}

.switchCheckbox {
    vertical-align: middle;
}