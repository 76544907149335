/*
// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

These styles are "borrowed" from Stax.ai (dev & owned by Aptus)
*/

.modal-container {
    position: fixed;
    left: 0;
    top: 42px;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    display: table;
    z-index: 9999;
    background-color: rgba(30,30,30,1);
    /* backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px); */
    /* animation: fadein 0.8s ease-in-out 0s 1 !important; */
}

.modal-content {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    padding: 40px;
    padding-bottom: 80px;
    /* transition: all 0.8s ease-in-out; */
    opacity: 1;
    animation: fadein 0.5s ease-in-out 0s 1 !important;
}

.modal-content-hidden {
    opacity: 0;
}

.multus-logo {
    animation: fadezoom 2s ease-in-out 0s 1 !important;
    opacity: 0;
}

.case-study-types, .study-menu {
    padding-top: 150px;
}

.case-study-type-thumb, .study-menu-option {
    display: inline-block;
    min-height: 250px;
    min-width: 200px;
    padding: 10px;
    margin: 0 20px;
    border-radius: 8px;
    text-align: center;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.case-study-type-thumb:hover, .study-menu-option:hover {
    background-color: rgba(220,220,220,0.06);
    transform: scale(1.05);
}

.case-study-type-thumb img {
    height: 200px;
}

.study-menu-option {
    width: 250px;
    height: 200px;
    padding: 20px;
}

.study-menu-option img, .study-menu-3d-bg {
    height: 150px;
    width: 250px;
}

.study-menu-3d-bg {
    background-position: calc(50% + 6px) 50%;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: rgb(24,24,24);
    position: relative
}

.study-menu-3d-bg img {
    position: absolute;
    left: 12px;
    bottom: 7px;
    width: 64px;
    height: 64px;
}

.case-study-type-thumb h1, .study-menu-option h1 {
    height: 40px;
    line-height: 40px;
    color: rgb(220,220,220);
    margin: 0;
    padding: 0;
    font-weight: 300;
    font-size: 28px;
}

.study-menu-option h1 {
    font-size: 20px;
    font-weight: 400;
    margin-top: 10px;
}

.case-menu-options {
    position: fixed;
    width: 500px;
    bottom: 80px;
    left: calc(50% - 250px);
    text-align: center;
}

.case-menu-link {
    display: inline-block;
    padding: 2px;
    margin: 0 30px;
    color: rgb(140,140,140);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    border-bottom: 2px solid transparent;
}

.case-menu-link:hover {
    /* color: #f09600; */
    color: white;
    border-bottom: 2px solid #f09600;
}

@keyframes fadein {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes fadezoom {
    0% {
        opacity: 0;
        transform: scale(0.99);
    }
    55% {
        opacity: 1;
    }
    85% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: scale(1.01);
    }
}