/*
Developed by Aptus Engineering, Inc. <https://aptus.aero>
See LICENSE.md file in project root directory
*/

.centerCard {
    display: inline-block;
    width: 345px;
    height: 135px;
    margin: 10px;
    padding: 6px;
    border-radius: 10px;
    background-color: rgba(200,200,200,0.5);
    transition: all 0.1s ease-in-out;
    position: relative;
    overflow: visible;
}

.centerInfoChanged {
    background-color: rgba(220,180,180,0.5);
}

.centerCard:hover {
    background-color: rgba(200,200,200,0.6);
    /* transform: scale(1.05); */
}

.centerInfoChanged:hover {
    background-color: rgba(220,180,180,0.6);
}

.centerButton {
    position: absolute;
    right: 5px;
    width: 72px;
    color: white;
    text-align: center;
    line-height: 24px;
    height: 24px;
    border-radius: 7px;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
}

.centerSaveButton {
    bottom: 34px;
    background-color: rgba(0,100,40,0.7);
}

.centerSaveButton:hover {
    background-color: rgba(0,140,80,0.7);
}

.centerConfirmButton {
    bottom: 34px;
    background-color: rgba(0,80,120,0.8);
}

.centerConfirmButton:hover {
    background-color: rgba(0,110,150,0.8);
}

.centerDeleteButton {
    bottom: 5px;
    background-color: rgba(100,0,0,0.7);
}

.centerDeleteButton:hover {
    background-color: rgba(140,0,0,0.7);
}

.centerReportButton {
    top: 5px;
    background-color: rgba(200,100,0,0.5);
}

.centerReportButton:hover {
    background-color: rgba(200,100,0,0.7);
}

.centerFilterSearch {
    border: none;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    width: 220px;
    height: 22px;
    line-height: 22px;
    font-size: 15px;
    background-color: rgba(245,245,245,0.3);
    color: rgb(40,40,40);
}

.centerFilterSearch::placeholder {
    color: rgb(80,80,80);
}

.centerCardLabel {
    color: rgb(120,120,120);
    height: 22px;
    width: 72px;
    line-height: 22px;
    padding-left: 10px;
    font-size: 13px;
    display: inline-block;
    margin-bottom: 6px;
}

.centerCard > input[type=text] {
    border: none;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    width: 155px;
    margin: 0;
    height: 22px;
    line-height: 22px;
    font-size: 13px;
    background-color: rgba(250,250,250,0.8);
    color: rgb(80,80,80);
    display: inline-block;
}

.centerCardSelect {
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 1px;
    height: 22px;
    line-height: 22px;
    width: 175px;
    overflow: hidden;
    background-color: rgba(250,250,250,0.8);
    color: rgb(80,80,80);
    border: none;
}

.switchContainer {
    border: none;
    border-radius: 5px;
    width: 175px;
    margin: 0;
    height: 22px;
    line-height: 22px;
    font-size: 13px;
    color: rgb(80,80,80);
    display: inline-block;
}

.switchLabel {
    display: inline-block;
    margin-left: 10px;
}

.switchCheckbox {
    vertical-align: middle;
}



.reportLoadingIcon {
    position: absolute;
    left: 25px;
    top: 3px;
}

.reportDateContainer .DateInput {
    display: none !important;
}

.reportDateContainer .DateRangePickerInput_arrow {
    display: none !important;
}

.reportDateContainer .DayPickerKeyboardShortcuts_showSpan__bottomRight {
    display: none !important;
}

.reportDateContainer .DayPickerKeyboardShortcuts_show__bottomRight {
    display: none !important;
}

.reportDateContainer .DateRangePicker_picker {
    top: 0px !important;
}

.reportDateContainer .CalendarDay__selected {
    /* Selected Start or End */
}

.reportDateContainer .CalendarDay__selected_span {
    /* Selected within the Start to End */

    /* background: rgba(255, 155, 30, 0.7) !important; */
    
}

.reportDateContainer .CalendarDay__hovered_span {
    /* Hovered over */

    /* border: 1px solid black !important; */
}