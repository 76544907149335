/*
Developed by Aptus Engineering, Inc. <https://aptus.aero>
See LICENSE.md file in project root directory
*/

* {
    margin: 0;
    padding: 0;
}

html, body {
    font-family: 'Barlow', sans-serif;
	font-size: 14px;
	width: 100%;
	height: 100%;
}

.navBar {
    width: calc(100% - 80px);
    height: 40px;
    line-height: 40px;
    padding: 0 40px;
    position: fixed;
    top: 0; 
    left: 0;
    z-index: 2;
    background-color: rgb(60,60,60);
    color: rgb(220,220,220);
    border-bottom: 2px solid rgb(120,120,120);
}

.navBar > img {
    height: 24px;
    margin-top: 8px;
    margin-left: -32px;
    position: fixed;
}

.navBar > h1 {
    height: 40px;
    font-size: 18px;
    font-weight: 200;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.navBar > ul {
    list-style: none;
    display: flex;
    position: fixed;
    top: 0;
    right: 40px;
    height: 40px;
}

.navBar > ul > li {
    font-size: 16px;
    margin-left: 24px;
    height: 40px;
}

.navBar > ul > li > a {
    color: rgb(220, 220, 220);
    font-weight: 200;
    text-decoration: none;
    transition: all 0.1s ease-in-out;
    display: block;
    height: 40px;
}

.navBar > ul > li > a:hover {
    color: rgb(230,155,30);
}

.navActive {
    border-top: solid 4px rgb(230,155,30);
    line-height: 32px;
}

.link {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 14px;
    margin-top: 36px;
    font-style: italic;
    color: rgb(80,80,80);
    transition: all 0.1s ease-in-out;
    cursor: pointer;
    text-decoration: underline;
}

.link:hover {
    color: rgb(230,155,30);
}

.credits {
    position: fixed;
    right: 10px;
    width: 150px;
    bottom: 0;
    z-index:100;
    opacity: 0.25;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
}

.credits:hover {
    opacity: 0.7;
}

.creditsText {
    color: rgb(100,100,100);
    font-size: 10px;
    font-style: italic;
    position: absolute;
    top: 24px;
}

.credits > img {
    width: 120px;
    height: auto;
    float: right;
}