/*
Developed by Aptus Engineering, Inc. <https://aptus.aero>
See LICENSE.md file in project root directory
*/

.userCard {
    display: inline-block;
    width: 345px;
    height: 108px;
    margin: 10px;
    padding: 6px;
    border-radius: 10px;
    background-color: rgba(200,200,200,0.5);
    transition: all 0.1s ease-in-out;
    position: relative;
    overflow: hidden;
}

.userInfoChanged {
    background-color: rgba(220,180,180,0.5);
}

.userCard:hover {
    background-color: rgba(200,200,200,0.6);
    /* transform: scale(1.05); */
}

.userInfoChanged:hover {
    background-color: rgba(220,180,180,0.6);
}

.userButton {
    position: absolute;
    right: 5px;
    width: 72px;
    color: white;
    text-align: center;
    line-height: 24px;
    height: 24px;
    border-radius: 7px;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
}

.userSaveButton {
    bottom: 34px;
    background-color: rgba(0,100,40,0.7);
}

.userSaveButton:hover {
    background-color: rgba(0,140,80,0.7);
}

.userConfirmButton {
    bottom: 34px;
    background-color: rgba(0,80,120,0.8);
}

.userConfirmButton:hover {
    background-color: rgba(0,110,150,0.8);
}

.userDeleteButton {
    bottom: 5px;
    background-color: rgba(100,0,0,0.7);
}

.userDeleteButton:hover {
    background-color: rgba(140,0,0,0.7);
}

.userExpandButton {
    background-color: rgba(180,180,180,0.5);
    top: 5px;
    bottom: 66px;
    color: rgb(80,80,80);
}

.userExpandButton:hover {
    background-color: rgba(180,180,180,1);
}

.userFilterType {
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    height: 22px;
    line-height: 22px;
    font-size: 14px;
    width: 110px;
    margin-right: 6px;
    overflow: hidden;
    background-color: rgba(245,245,245,0.3);
    color: rgb(40,40,40);
    border: none;
}

.userFilterSearch {
    border: none;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    width: 220px;
    height: 22px;
    line-height: 22px;
    font-size: 15px;
    background-color: rgba(245,245,245,0.3);
    color: rgb(40,40,40);
}

.userFilterSearch::placeholder {
    color: rgb(80,80,80);
}

.userCardLabel {
    color: rgb(120,120,120);
    height: 22px;
    width: 72px;
    line-height: 22px;
    padding-left: 10px;
    font-size: 13px;
    display: inline-block;
    margin-bottom: 6px;
}

.userDataLabel {
    color: rgb(120,120,120);
    height: 22px;
    width: 80px;
    line-height: 22px;
    font-size: 13px;
    display: inline-block;
    margin-bottom: 6px;
}

.resetPasswordButton {
    background-color: rgba(0,80,120,0.8);
    color: white;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    position: absolute;
    left: 88px;
    top: 62px;
    width: 175px;
    height: 24px;
    line-height: 24px;
    cursor: pointer;
    opacity: 0;
    z-index: 10;
}

.resetPasswordButton:hover {
    opacity: 1;
}

.userCard > input[type=text], .userCard > input[type=password], .userDataContent > input[type=text] {
    border: none;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    width: 155px;
    margin: 0;
    height: 22px;
    line-height: 22px;
    font-size: 13px;
    background-color: rgba(250,250,250,0.8);
    color: rgb(80,80,80);
    display: inline-block;
}

.userDataContainer {
    position: absolute;
    left: 279px;
    top: 6px;
    bottom: 6px;
    right: 94px;
}

.userDataContent {
    position: relative;
}

.radImage {
    position: absolute;
    top: 1px;
    width: 135px;
    height: 50px;
    margin: 0;
    padding: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border-radius: 5px;
    background-color: white;
}

.radImageOverlay {
    width: 135px;
    height: 50px;
    background-color: rgba(80,80,80,0.8);
    border-radius: 5px;
    text-align: center;
    line-height: 50px;
    color: white;
    opacity: 0.1;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.radImageOverlay:hover {
    opacity: 1;
}

.userDataSelect {
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 1px;
    height: 22px;
    line-height: 22px;
    width: 100%;
    overflow: hidden;
    background-color: rgba(250,250,250,0.8);
    color: rgb(80,80,80);
    border: none;
}

.userTypeSelect {
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 1px;
    height: 22px;
    line-height: 22px;
    width: 175px;
    overflow: hidden;
    background-color: rgba(250,250,250,0.8);
    color: rgb(80,80,80);
    border: none;
}