.model-view {
    height: 100%;
}

/* Labels list */
.labels-component {
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.viewButtonContainer {
    position: absolute;
    bottom: 10px;
    left: calc(50% - 190px);
}

.zoomContainer {
    position: absolute;
    bottom: 10px;
    left: 10px;
}

.viewButton {
    background-color: rgba(20,20,20,0.7);
    outline: none !important;
    border: none !important;
    width: 100px;
    height: 68px;
    color: #eee;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.15s ease-in-out;
    margin: 0 10px;
}

.viewButton:hover {
    background-color: rgba(50,50,50,0.7);
    color: #ff9b1e;
}

.zoomButton {
    background-color: rgba(20,20,20,0.7);
    outline: none !important;
    border: none !important;
    color: #eee;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.15s ease-in-out;
    padding: 24px !important;
    margin: 0px 10px;
}

.zoomButton:hover {
    background-color: rgba(50,50,50,0.7);
}

.zoomSVG {
    width: 20px;
    opacity: .8;
}

.rotateContainer {
    position: absolute;
    bottom: 10px;
    left: 10px;
    top: 10px;
    right: 10px;
    pointer-events: none;
}

.rotateButton {
    background-color: rgba(20,20,20,0.7);
    outline: none !important;
    border: none !important;
    color: #eee;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.15s ease-in-out;
    padding: 10px !important;
    margin: 0px 10px;
    pointer-events: all;
}

.rotateImage {
    width: 32px;
}

.rotateButton:hover {
    background-color: rgba(50,50,50,0.7);
}

.rotateUp {
    position: absolute;
    top: 10px;
    left: calc(50% - 44px)
}

.rotateDown {
    position: absolute;
    bottom: 80px;
    left: calc(50% - 44px);
    transform: rotate(180deg);
}

.rotateRight {
    position: absolute;
    top: calc(50% - 41px);
    right: 10px;
    transform: rotate(90deg);
}

.rotateLeft {
    position: absolute;
    top: calc(50% - 41px);
    left: 10px;
    transform: rotate(270deg);
}

/* .zoomSVG {
    width: 32px;
    height: 32px;

    fill: #fff
} */

.labels-component .labels {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 500px;
    color: white;
    display: flex;
    flex-direction: column;
}

.labels-component .labels button {
    pointer-events: auto;
    cursor: pointer;
    color: white;
    fill: white;
    background: none;
    border: none;
    font-size: 16px;
    margin: 20px 10px 5px 10px;
    padding: 10px;
    background-color: rgba(70, 70, 70, 200);
    text-align: left;
}

.labels-component .labels button svg {
    width: 10px;
    height: 10px;
    margin-right: 6px;
    margin-bottom: 2px;
}
  
.labels-component .labels .labelItem {
    display: block;
    background-color: rgba(70, 70, 70, .4);
    margin: 5px 10px;
    padding: 10px;
}

.labels-component #label-canvas {
    position: absolute;
    top: 0;
    right: 0;
}
  
  
/* Object List component */
.objectList {
    position: absolute;
    height: auto;
    max-height: calc(100% - 20px);
    padding: 10px;
    margin: 0;
    overflow: auto;
    color: white;
}

.objectList li:first-child {
    padding-top: 16px;
}

.objectList li:last-child {
    padding-bottom: 16px;
}

.objectList .rc-tree-checkbox {
    display: none !important;
}

.objectList .rc-tree-switcher {
    background: none !important;
}

.objectList .rc-tree-title {
    padding-left: 5px !important;
}

.objectList .rc-tree-switcher-icon {
    fill: rgb(100,100,100) !important;
}

/* Cube view canvas */
#viewCubeCanvas {
    position: absolute;
    pointer-events: none;
    bottom: 20px;
    right: 20px;
    color: white !important;
    margin-right: 0 !important;
}

.hiddenInstructions {
    position: absolute;
    left: 240px;
    top: 10px;
    background-color: rgba(20,20,20,0.2);
    color: #fff;
    width: 180px;
    font-size: 12pt;
    font-weight: 400;
    text-align: center;
    transition: all 0.15s ease-in-out;
    cursor: pointer;
    border-radius: 6px;
    padding: 24px 20px;
}

.hiddenInstructions:hover {
    background-color: rgba(50,50,50,0.7);
    color: #ff9b1e;
}

.shownInstructions {
    position: absolute;
    left: 240px;
    top: 10px;
    background-color: rgba(20,20,20,0.7);
    color: #fff;
    width: 400px;
    text-align: center;
    transition: all 0.15s ease-in-out;
    cursor: pointer;
    border-radius: 6px;
}

.shownInstructions:hover {
    background-color: rgba(50,50,50,0.7);
    color: #ff9b1e;
}

.controlDiv {

    display: inline-block;
    border-bottom: solid rgba(120,120,120,0.7) 1px;
    border-top: solid rgba(120,120,120,0.7) 1px;

}

.controlType {
    display: table-cell;
    font-size: 14pt;
    border-right: solid rgba(255, 180, 20, .7) 2px;
    vertical-align: middle;
    position: relative;
}

.controlType p {
    width: 100px;
}

.controlExplanation {
    display: table-cell;
    width: 300px;
    vertical-align: middle;
    padding: 16px;
}