.statsRootContainer {
  position: fixed;
  z-index: 0;
  width: 100%;
  height: 100%;
  /* position: relative;
  z-index: 0;
  width: 100%;
  height: 100%;
  top:10em;
  text-align: center; */
}
select.center{
  font-size:2em
}

.statsContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background-image: url('/resources/cover.jpg');
    background-size: cover;
    /* min-width: 1300px; */
    overflow-y: scroll;
    text-align: center;

    display: flex;
  justify-content: center;
  align-items: center;
}

.statsUI {

  
}


/* todo: import this in the dropzone component, not global */

.statsContainer .dropzone {
    transition: all 0.4s;
    /* margin: 1em; */
    border:4px solid rgb(120,120,120);
    border-radius: 10px;
   
    cursor:pointer;
    text-align: center;
  
    
    background-color: #94949489 !important;
    overflow: hidden;
    position: relative;
    float:none;
    width: 100%;
    margin: 0 auto;
    top:1em
  }
  .dropzone svg{
    font-size: 3rem;
  }
  .dropzone.active {
    background-color: #ff0000;
  }
   .dropzone:hover{
    border:4px solid rgb(230,155,30);
    background-color :#333333 !important;
    color:white
  }
  .dropzone.over {
    border:2px solid black;
    background-color: rgb(60,60,60);
    color:white
    /* background:#ff0000 */
  }
  .dropzone  p {
    pointer-events: none;
    margin-bottom: 0;
    top: 40%;
    position: absolute;
    text-align: center;
    width: 100%;
    font-size: 1.4rem;
  }
  .dropzone {
    pointer-events: all;
  
  }
  /* .dropzone  div[role="presentation"] {
    pointer-events: painted;
    padding: 1em;
  } */
  .dropzone  div {
    height:100%
  }
  
  
  .dropzone  div * {
    /* pointer-events: none; */
  }
  
  