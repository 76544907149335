/*
Developed by Aptus Engineering, Inc. <https://aptus.aero>
See LICENSE.md file in project root directory
*/

.loginContainer {
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('/resources/cover.jpg');
    background-size: cover;
}

.loginAdvertising {
    position: absolute;
    top: calc(50% - 250px);
    left: 15%;
    width: calc(35% - 100px);
    bottom: calc(50% - 250px);
    padding-right: 100px;
    padding-top: 50px;
    padding-bottom: 50px;
    border-right: 1px solid rgb(200,200,200);
}

.loginForm {
    position: absolute;
    top: calc(50% - 270px);
    right: 15%;
    width: calc(35% - 100px);
    bottom: calc(50% - 270px);
    padding-left: 100px;
    padding-top: 100px;
    padding-bottom: 100px;
}

.loginError {
    font-size: 16px;
    font-style: italic;
    margin-top: 30px;
    line-height: 16px;
    color: red;
    width: 100%;
    text-align: center;
}

@media only screen and (max-width: 1280px) {
    .loginAdvertising {
        display: none;
    }

    .loginForm {
        width: 100%;
        right: 50%;
        margin-right: -50%;
    }
}

.loginForm > h1 {
    width: 100%;
    max-width: 400px;
    height: 40px;
    margin: 0 auto;
    line-height: 40px;
    font-size: 32px;
    font-weight: 300;
    text-align: center;
    border-bottom: 1px solid #ccc;
    padding-bottom: 20px;
    color: rgb(80,80,80);
}

.loginForm > form {
    width: 100%;
    max-width: 400px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    font-size: 14px;
}

.loginForm > form > input[type=text], .loginForm > form > input[type=password] {
    width: 100%;
    margin-top: 20px;
    padding: 12px 20px;
    display: inline-block;;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: rgba(255,255,255, 0.8);
    font-size: 14px;
    color: rgb(80,80,80);
}

.loginForm > form > input[type=submit] {
    width: 100%;
    margin-top: 20px;
    background-color: rgb(220,130,25);
    color: white;
    padding: 14px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: all 0.1s ease-in-out;
}

.loginForm > form > input[type=submit]:hover {
    background-color: rgb(230,155,30);
}