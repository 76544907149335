.opsLienRowTaskContainer {
    width: calc(100% - 120px);
}

.lienTaskRowItem {
    float: left;
    padding: 0 5px;
    height: 32px;
    line-height: 32px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.notesContainer {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
}

.notesManager {
    position: absolute;
    width: 300px;
    top: 0px;
    right: 0;
    bottom: 0;
    background-color: rgb(250,250,250);
    border-left: 1px solid rgb(160,160,160);
    box-shadow: -5px 0 10px -2px rgba(0,0,0,0.3);
    transition: 0.1s all ease-in-out;
    padding-left: 10px;
    padding-right: 22px;
}

.notesInput {
    border-radius: 5px;
    margin-top: 100px;
    margin-left: 6px;
    width: 90%;
    max-width: 90%;
    height: 100px;
    max-height: 750px;
    line-height: 13px;
    background-color: rgba(245,245,245,0.5);
    color: rgb(80,80,80);
    border: 1px solid rgb(210,210,210);
}

.notesSave {
    width: 80px;
    cursor: pointer;
    margin-top: 10px;
    margin-left: 110px;
}

.notesSave:hover {
    color: rgb(110,110,110)
}

.notesPreview {
    position: fixed;
    right: 120px;
    background-color: rgba(245,245,245,0.7);
    border: solid 1px rgba(220,220,220, 0.7);
    border-radius: 10px;
    padding: 5px;
    margin-top: 5px;
    max-width: 300px;
    max-height: 80%;
    z-index: 5;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
}

.followUpDateContainer .DateInput {
    display: none !important;
}