.tasksRootContainer {
    position: fixed;
    z-index: 0;
    width: 100%;
    height: 100%;
}

.tasksContainer {
    position: absolute;
    top: 72px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background-image: url('/resources/cover.jpg');
    background-size: cover;
    min-width: 1300px;
    overflow-y: scroll;
}

.taskDateSelect {
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 5px;
    padding-left: 2px;
    padding-right: 2px;
    height: 22px;
    line-height: 22px;
    overflow: hidden;
    background-color: rgba(225,225,225,0.8);
    color: rgb(80,80,80);
    border: none;
    text-align: center;
}

.opsLienTaskRow {
    position: relative;

    height: 32px;
    
    margin-top: 6px;
    margin-left: 40px;
    margin-right: 40px;

    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 20px;
    padding-right: 20px;

    line-height: 20px;

    color: rgb(40,40,40);
    background-color: rgba(160,160,160,0.3);
    transition: all 0.1s ease-in-out;

    overflow: hidden;
}

.opsLienTaskRow:hover {
    margin-left: 10px;
    margin-right: 10px;

    padding-left: 50px;
    padding-right: 50px;

    background-color: rgba(160,160,160,0.4);
}

.opsLienTaskRow > img {
    width: 30px;
    height: 30px;
    float: right;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    margin-left: 10px;
}

.taskInvoiceNumberInput {
    border-radius: 5px;
    padding-top: 4px;
    padding-left: 5px;
    padding-right: 5px;
    height: 24px;
    line-height: 30px;
    background-color: rgba(245,245,245,0.6);
    color: rgb(80,80,80);
    border: 1px solid rgb(210,210,210);
    width: 75%;
}

.followUpDate {
    cursor: pointer;
    text-align: center;
    background-color: rgba(247,225,187,0.5);
}

.followUpDate:hover {
    background-color: rgba(252,245,232,0.5);
}
