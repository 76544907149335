/*
Developed by Aptus Engineering, Inc. <https://aptus.aero>
See LICENSE.md file in project root directory
*/


.injuryEditorRoot {
    z-index: 10;
    height: 100%;
    width: auto;
    transition: ease-in-out 2s all;
}

.injuryEditorRootHidden{
    width: 0;
}

.injuryEditorContainer {
    position: absolute;
    top:0;
    left: 0;
    width: 650px;
    bottom: 40px;
    z-index: 1;
    overflow: hidden;
    background-color: rgb(40,40,40);
    transition: ease-in-out 0.15s all;

    min-width: 250;
    max-width: 650;
}

.injuryEditorContainerHidden {
    /* width: 0px; */
    transform: translateX(-100%);

}
.injuryEditorControls {
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 0;
    height: 40px;
    z-index: 1;
    overflow: hidden;
    background-color: rgb(20,20,20);
    transition: ease-in-out 0.15s all;
}

.injuryEditorControlsHidden {
    transform: translateX(-100%);
}

.injuryEditorPane {
    width: calc(100% - 22px);
    margin: 11px;
}

.injuryLocation {
    width: calc(100% - 12px);
    padding: 5px;
    margin-top: 8px;
    border: 1px solid rgb(70,70,70);
    color: rgb(160,160,160);
    cursor: pointer;
    overflow: hidden;
    transition: all 0.15s ease-in-out;
}

.injuryLocation:hover {
    background-color: rgb(45,45,45);
}

.locationHeader {
    color: rgb(100,240,240);
    margin: -5px;
    height: 18px;
    line-height: 18px;
    width: calc(100% - 6px);
    padding: 5px 8px;
    margin-bottom: 5px;
    transition: all 0.15s ease-in-out;
}

.locationHeader:hover {
    background-color: rgb(50,50,50);
}

.injuryObject {
    width: calc(100% - 18px);
    border: 1px solid rgb(70,70,70);
    margin-top: 5px;
    margin-left: 17px;
}

.injuryHeader {
    height: 24px;
    line-height: 24px;
    width: calc(100% - 10px);
    padding: 0 5px;
    transition: all 0.15s ease-in-out;
    text-decoration: line-through;
}

.injuryHeaderActive {
    text-decoration: none;
    background-color: rgb(60,60,60);
}

.injuryHeader:hover {
    background-color: rgb(70,70,70) !important;
}

.injuryHeader > img {
    width: 16px;
    height: 16px;
    float: right;
    margin-top: 4px;
    transition: all 0.15s ease-in-out;
}

.injuryHeader > img:hover {
    transform: scale(1.2);
}

.injurySummary {
    width: calc(100% - 26px);
    height: 80px;
    min-height: 60px;
    min-width: calc(100% - 26px);
    max-width: calc(100% - 26px);
    background-color: rgb(40,40,40);
    border: 1px solid rgb(70,70,70);
    margin-left: 17px;
    margin-top: 5px;
    padding: 4px;
    color: rgb(180,180,180);
    font-size: 15px;
    font-family: Arial, Helvetica, sans-serif;
}

.injurySummary:hover {
    border: 1px solid rgb(220, 100, 0);
}

.injuryProp {
    margin: 5px;
    margin-top: 0;
    height: 20px;
    line-height: 20px;
}

.injuryPropLabel {
    width: 50%;
    max-width: 110px;
    float: left;
}

.injuryPropSelect {
    width: 50%;
    float: right;
    height: 22px;
    line-height: 22px;
    background-color: rgb(40,40,40);
    border: 1px solid rgb(80,80,80);
    color: rgb(180,180,180);
    cursor: pointer;
    transition: all 0.15s ease-in-out;
}

.injuryPropSelect:hover {
    border: 1px solid rgb(220, 100, 0);
}


.editorControlsButton {
    float: left;
    /* width: 220px; */
    height: 28px;
    margin-left: 1.25%;
    margin-right: 1.25%;
    margin-top: 5px;
    line-height: 28px;
    color: white;
    text-align: center;
    background-color: rgb(160,100,0);
    border: 1px solid rgb(200,140,0);
    cursor: pointer;
    transition: all 0.15s ease-in-out;
}

.editorControlsButton:hover {
    background-color: rgb(200,140,0);
    border: 1px solid rgb(160,100,0);
}

.injuryEditorSave {
    width: 20%;
    background-color: rgb(0, 138, 41);
    border: 1px solid rgb(0, 200, 50);
}

.injuryEditorSave:hover {
    background-color: rgb(0, 200, 50);
    border: 1px solid rgb(0, 138, 41);
}

.injuryEditorPlace {
    background-color: rgb(160,100,0);
    border: 1px solid rgb(200,140,0);
    width: 50%;
}

.injuryEditorPlace:hover {
    background-color: rgb(200,140,0);
    border: 1px solid rgb(160,100,0);
}

.injuryEditorNext {
    width: 20%;
    background-color: rgb(0,120,160);
    border: 1px solid rgb(0,160,200);
}

.injuryEditorNext:hover {
    background-color: rgb(0,160,200);
    border: 1px solid rgb(0,120,160);
}
 
.hideToggleContainer {
    position: absolute;
    bottom: 0;
    left: 100%;
    width: 16px;
    height: 16px;
    padding: 12px;

    z-index: 10;
    border-radius: 0 5px 0 0;
    color: white;
    background-color: rgb(20,20,20);
    cursor: pointer;
    transition: all 0.15s ease-in-out;
}

.hideToggleContainerHidden {
    left: 0px;
}

.hideToggleButton {
    width: 16px;
    height: 16px;
    transition: all 0.15s ease-in-out;
}

.hideToggleButtonHidden {
    transform: rotate(180deg);
}

.hideToggleContainer:hover {
    background-color: rgb(0,160,200);
    /* border: 1px solid rgb(0,120,160); */
}

.injuryEditorLoading {
    position: absolute;
    left: 50%;
    margin-left: -16px;
    top: 4px;
}