/*
Developed by Aptus Engineering, Inc. <https://aptus.aero>
See LICENSE.md file in opsProject root directory
*/

.opsProjectRow {
    position: relative;

    height: 32px;
    
    margin-top: 6px;
    margin-left: 40px;
    margin-right: 40px;

    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 20px;
    padding-right: 20px;

    line-height: 20px;

    color: rgb(40,40,40);
    background-color: rgba(160,160,160,0.3);
    transition: all 0.1s ease-in-out;

    overflow: hidden;

    cursor: pointer;
}

.opsProjectRowSelected {
    border: 2px solid rgb(230,155,30);
}

.opsProjectRow:hover {
    margin-left: 10px;
    margin-right: 10px;

    padding-left: 50px;
    padding-right: 50px;

    background-color: rgba(160,160,160,0.4);
}

.opsProjectRowUpload {
    height: 80px;
    margin-top: 40px;
    transition: all 0.1s ease-in-out;
}

.opsProjectRowItemContainer {
    width: calc(100% - 120px);
}

.opsProjectRowItem {
    float: left;
    padding: 0 5px;
    height: 32px;
    line-height: 32px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.opsProjectRow > img {
    width: 30px;
    height: 30px;
    float: right;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    margin-left: 10px;
}

.opsProjectRow > img:hover {
    transform: scale(1.15);
}

.contactInfo {
    margin-top: 42px;
}

.contactInfoRow {
    height: 36px;
    line-height: 30px;
    text-align: right;
}

.contactInfoInput {
    border-radius: 5px;
    padding-top: 4px;
    padding-left: 5px;
    padding-right: 5px;
    height: 24px;
    line-height: 30px;
    background-color: rgba(245,245,245,0.5);
    color: rgb(80,80,80);
    border: 1px solid rgb(210,210,210);
    margin-left: 6px;
    width: 160px;
}

.opsSelect {
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
    height: 28px;
    line-height: 22px;
    width: 100%;
    overflow: hidden;
    background-color: rgba(245,245,245,0.3);
    color: rgb(40,40,40);
    border: none;
}

.caseGrouping {
    background-color: rgba(120,120,120,0.9);
    color: white;
    position: fixed;
    bottom: 20px;
    left: 20px;
    padding: 5px 10px;
    border-radius: 8px;
    height: 24px;
    line-height: 24px;
}

.caseGroupButton {
    display: inline-block;
    height: 14px;
    line-height: 14px;
    background-color: rgb(80,80,80);
    color: white;
    padding: 5px 10px;
    transition: all 0.1s ease-in-out;
    margin-left: 10px;
    border-radius: 5px;
}

.caseGroupButton:hover {
    transform: scale(1.1);
    cursor: pointer;
    background-color: rgb(60,60,60);
    color: rgb(230,155,30);
}

.aiProcessing {
    float: left;
    padding-top: 4px;
}

.confirmGroup {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.6);
    z-index: 10;
    top: 40px;
}

.confirmGroupDialog {
    position: fixed;
    text-align: center;
    left: 50%;
    top: 25%;
    width: 400px;
    background-color: white;
    opacity: 1;
    margin-left: -200px;
    border: 1px solid rgb(220,220,220);
}

.confirmGroupDialogTitle {
    color: rgb(100,100,100);
    width: 90%;
    font-size: 15px;
    margin-left: 5%;
    line-height: 25px;
    margin-top: 10px;
    margin-bottom: 9px;
    border-bottom: 1px solid rgb(220,220,220);
}


.newCaseButton{
    color: white;
    position: fixed;
    bottom: 0;
    right:0;
    z-index: 10;
    height: 40px;
    width: 40px;
    background: rgb(230,155,30);
    font-weight: bold;
    border: 0;
    font-size: 2em;
    cursor: pointer;
}

.newCase {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.6);
    z-index: 10;
    top: 40px;
}

.newCaseDialog {
    position: fixed;
    text-align: center;
    left: 50%;
    top: 25%;
    width: 400px;
    background-color: white;
    opacity: 1;
    margin-left: -200px;
    border: 1px solid rgb(220,220,220);
}

.newCaseDialog select,
.newCaseDialog input{
    margin-bottom: 10px;
}

.newCaseDialog button{
    padding:1em;
    background:rgb(230,155,30);
    font-weight: 600;
    color:white;
    border:0;
    cursor:pointer;
}
.newCaseDialog button:hover{
    background:rgb(255,205,80);
}

.newCaseDialogTitle {
    color: rgb(100,100,100);
    width: 90%;
    font-size: 15px;
    margin-left: 5%;
    line-height: 25px;
    margin-top: 10px;
    margin-bottom: 9px;
    border-bottom: 1px solid rgb(220,220,220);
}

.groupDialogBtn {
    display: inline-block;
    margin-bottom: 10px;
    margin-top: 20px;
    width: 25px;
    cursor: pointer;
    text-align: center;
}

.caseInfoRow {
    background-color: rgba(240, 200, 0, 0.3);
    transition: all 0.1s ease-in-out;

    margin-top: 6px;
    margin-left: 40px;
    margin-right: 40px;

    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 20px;
    padding-right: 20px;
}

.caseInfoRow > img {
    position: absolute;
    cursor: pointer;
    right: 45px;
    width: 17px;
}

.caseInfoRow:hover {
    margin-left: 10px;
    margin-right: 10px;

    padding-left: 50px;
    padding-right: 50px;
}

.caseInfoTable {
    display: inline-block;
}

.opsProjectRowOpen {
    height: 112px;
}