/*
Developed by Aptus Engineering, Inc. <https://aptus.aero>
See LICENSE.md file in project root directory
*/

.centerProjectRow {
    height: 20px;
    
    margin-top: 6px;
    margin-left: 40px;
    margin-right: 40px;

    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;

    line-height: 20px;

    color: rgb(40,40,40);
    background-color: rgba(160,160,160,0.3);
    transition: all 0.1s ease-in-out;

    overflow: hidden;
}

.centerProjectRow:hover {
    margin-left: 10px;
    margin-right: 10px;

    padding-left: 50px;
    padding-right: 50px;

    background-color: rgba(160,160,160,0.4);
}

.endUserSelect {
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
    height: 22px;
    line-height: 22px;
    width: 75%;
    overflow: hidden;
    background-color: rgba(245,245,245,0.3);
    color: rgb(40,40,40);
    border: none;
}

.centerProjectRowUpload {
    height: 80px;
    margin-top: 40px;
    transition: all 0.1s ease-in-out;
}

.documentUploadBox {
    height: 76px;
    width: 140px;
    border-radius: 6px;
    float: right;
    margin-left: 20px;
    border: 2px dashed rgba(160,160,160,1);
    background-color: rgba(200,200,200,0.3);
    cursor: pointer;
    opacity: 0.5;
    transition: all 0.1s ease-in-out;
    position: relative;
}

.documentUploadBox:hover {
    opacity: 1;
}

.documentUploadBox > img {
    height: 24px;
    width: 24px;
    margin-left: 58px;
    margin-top: 16px;
}

.documentType {
    width: 100%;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: rgb(40,40,40);
    font-size: 14px;
}

.centerProjectRowItem {
    float: left;
    padding: 0 5px;
    height: 24px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.centerProjectRow > img {
    width: 30px;
    height: 30px;
    float: right;
    margin-top: -6px;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    padding-right: 5px;
}

.centerProjectRow > img:hover {
    transform: scale(1.15);
}

.centerProjectImg {
    width: 30px;
    height: 30px;
    float: right;
    margin-top: -6px;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    padding-right: 5px;
}

.centerProjectImg:hover {
    transform: scale(1.15);
}

.loadingIcon {
    position: absolute;
    height: 76px;
    width: 140px;
    left: 0;
    top: 0;
}

.reviewDocsButton {
    background-color: rgb(80,80,80);
    color: rgb(220,220,220);
    padding: 2px 10px;
    border-radius: 12px;
    width: 62px;
    transition: all 0.1s ease-in-out;
    cursor: pointer;
    background-image: url('/resources/icons/next.png');
    background-repeat: no-repeat;
    background-size: 24px;
    background-position: 58px 0;
}

.reviewDocsButton:hover {
    background-color: rgb(60,60,60);
    color: rgb(230,155,30);
    width: 70px;
    background-position: 66px 0;
}