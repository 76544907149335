/*
Developed by Aptus Engineering, Inc. <https://aptus.aero>
See LICENSE.md file in project root directory
*/

/* Case table search bars */
.searchBar {
    width: calc(100% - 130px);
    padding-right: 75px;
    padding-left: 60px;
    padding-top: 3px;
    background-color: rgb(132,132,132);
    color: white;
    line-height: 30px;
    position: absolute;
    top: 70px;
    overflow: hidden;
    z-index: 101;
    min-width: 1090px;
}

.searchBarField {
    display: inline-block;
    padding-left: 2px;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.05s ease-in-out;
}

.searchBarField > input[type=text] {
    border: none;
    border-radius: 5px;
    padding-top: 4px;
    padding-left: 5px;
    padding-right: 5px;
    width: calc(100% - 20px);
    height: 20px;
    line-height: 20px;
    background-color: rgba(255,255,255,0.1);
    color: white;
}

.advancedSearch {
    float: right; 
    padding-right: 8px;
    color: rgb(220,220,220);
    cursor: pointer;
    transition: all 0.05s ease-in-out;
}

.advancedSearch:hover {
    color: rgb(220,130,25);
}